import React, { useState, PureComponent, useEffect } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import { useSelector, shallowEqual } from 'react-redux';
import { clientsRequest } from '../../requests/DirectWayProRrequest';



// PureComponent ensures that the markers are only rerendered when data changes
class Markers extends PureComponent {
  render() {
    const {data} = this.props;
    return data.map(
      city => <Marker key={city.code} longitude={city.longitude} latitude={city.latitude} >
          <img src={toAbsoluteUrl("/media/icons/marker.png")}/>
            </Marker>
    )
  }
}


export default function Dashboard() {
  const { subscription, token } = useSelector(({ auth }) => ({ subscription: auth.subscription, token: auth.authToken }), shallowEqual);

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: 36.7034368,
    longitude: 3.063808,
    zoom: 8
  });
  const [clientState, setClientState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    
    // Get Clients request function
    setIsLoadingState(true);
    async function getClients(){
      clientsRequest(token, subscription).then(res => {
        setIsLoadingState(false);
        setClientState(res.data);
      }).catch(err => {
        setIsLoadingState(false);
        console.log('Error is ', err);
      });
    }
    getClients();
  }, [subscription])



  return (
    <ReactMapGL
      
      mapboxApiAccessToken={'pk.eyJ1IjoiZ2FmZmxvcmQiLCJhIjoiY2s5YnNjOTI2MDk2ZzNnbm40NjRtYmR3dSJ9.sI-Uh2Me92tSGKcc4TuFRw'}
      {...viewport}
      onViewportChange={setViewport}
    >
        <Markers data={clientState} />
      </ReactMapGL>
  );
}


// import React from "react";
// import { GoogleMap, withGoogleMap, withScriptjs} from 'react-google-maps';


// function Map(){
//   return(
//     <GoogleMap 
//       defaultZoom={10}
//       defaultCenter={{lat: 36.737344, lng: 3.178949}}
//     />
//   );
// }

// const WrappedMap = withScriptjs(withGoogleMap(Map))

// export default function Dashboard() {
//   return (
//     <div style={{width: '100%', height: '100%'}}>
//       <WrappedMap
//         googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,palces'}
//         loadingElement={<div style={{height: '100%'}}/>}
//         containerElement={<div style={{height: '100%'}}/>}
//         mapElement={<div style={{height: '100%'}}/>}
//       />
//     </div>
//   );
// }











// import React from "react";
// import { GoogleMap, withGoogleMap, withScriptjs} from 'react-google-maps';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

// // function Map(){
// //   return(
// //     <GoogleMap 
// //       defaultZoom={10}
// //       defaultCenter={{lat: 36.737344, lng: 3.178949}}
// //     />
// //   );
// // }

// // const WrappedMap = withScriptjs(withGoogleMap(Map))
// const position = [ 51.505, -0.09]

// export default function Dashboard() {
//   return (
//     <div >
//      <Map center={position} zoom={10} style={{width: '50%', height: '100%'}}>
//         <TileLayer
//           attribution=''
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          
//         />
//         <Marker position={position}>
//           <Popup>
//             A pretty CSS3 popup. <br /> Easily customizable.
//           </Popup>
//         </Marker>
//       </Map>
//     </div>
//   );
// }
