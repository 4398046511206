/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { LayoutContextConsumer } from "../LayoutContext";
import { ReactComponent as SortNum1Icon } from "../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg";
import * as builder from "../../ducks/builder";
// import BreadCrumbs from "./components/BreadCrumbs";


import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));



const SubHeader = props => {
  
  const {
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle
  } = props;
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="Breadcrumb">
    <Link color="inherit" href="/"  className={classes.link}>
      <HomeIcon className={classes.icon} />
      Material-UI
    </Link>
    <Link
      color="inherit"
      href="/getting-started/installation/"
      className={classes.link}
    >
      <WhatshotIcon className={classes.icon} />
      Core
    </Link>
    <Typography color="textPrimary" className={classes.link}>
      <GrainIcon className={classes.icon} />
      Breadcrumb
    </Typography>
  </Breadcrumbs>
  );
    
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  })
});

export default withRouter(connect(mapStateToProps)(SubHeader));
