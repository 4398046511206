import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

import {subscriptions} from '../../requests/DirectWayProRrequest';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserData: "[UserData] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  subscription: undefined
};

export const reducer = persistReducer(
    { storage, key: "directwaypro", whitelist: ["user", "authToken", "subscription"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {

          const { subscription, authToken } = action.payload;

          return { authToken, user: state.user, subscription };
        }

        case actionTypes.Logout: {
          //routerHelpers.forgotLastLocation();
          localStorage.clear();
          return initialAuthState;
        }

        case actionTypes.UserData: {
          const {user} = action.payload;

          return {authToken: state.authToken, subscription: state.subscription, user: user};
        }

        default:
          return state;
      }
    }
);

export const actions = {
  login: (subscription, authToken) => ({ type: actionTypes.Login, payload: { subscription, authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  userData: user =>({type: actionTypes.UserData, payload :{ user}})
};

// export function* saga() {

//   yield takeLatest(actionTypes.Login, function* loginSaga() {
//     const { subscription } = yield subscriptions();
//     yield put(actions.login(subscription));
//   });

//   // yield takeLatest(actionTypes.logout, function* logoutSaga() {
//   //   yield put(actions.logout());
//   // });

//   // yield takeLatest(actionTypes.Register, function* registerSaga() {
//   //   yield put(actions.requestUser());
//   // });

//   // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
//   //   const { data: user } = yield getUserByToken();

//   //   yield put(actions.fulfillUser(user));
//   // });
// }
