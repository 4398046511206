import axios from "axios";

const BASE_URL = "https://directway.pro/api";
//const BASE_URL = "http://localhost:4429/api";
const SUBSCRIPTION_URL = `${BASE_URL}/Customers/UserSubscriptions`;
const CLIENT_COUNT_URL = `${BASE_URL}/Client/GetClientsCount`;
const CLIENT_LIST_URL = `${BASE_URL}/Client/GetClients`;
const DOCUMENTS_MODEL_URL = `${BASE_URL}/Customers/GetDocuments`;
const GENERATE_PDF_DOCUMENT = `${BASE_URL}/Client/GeneratePDF`;
const DELETE_CLIENTS = `${BASE_URL}/Client/DeleteClients`;
const USER_INFO = `https://api.inabex.online/api/account/profile`;

export function subscriptions(token) {

  return axios.get(SUBSCRIPTION_URL, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
}

export function clientCount(token, subscription){
  return axios.get(CLIENT_COUNT_URL, {
    headers:{
      "Authorization" : `Bearer ${token}`,
      "Subscription" : subscription
    }
  });
}

export function clientsRequest(token, subscription){
  return axios.get(CLIENT_LIST_URL, {
    headers:{
      "Authorization" : `Bearer ${token}`,
      "Subscription" : subscription
    }
  });
}

export function documentModelRequest(token){
  return axios.get(DOCUMENTS_MODEL_URL, {
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  });
}

export function generatePDFDocument(clients, document, token, subscription){
  return axios.post(GENERATE_PDF_DOCUMENT,{
    "Clients": clients,
    "ModelCode": document
  },{
    headers:{
      "Authorization" : `Bearer ${token}`,
      "Subscription" : subscription
    }
  });
}

export function userInfo(token){
  return axios.get(USER_INFO, {
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
}

export function deleteClients(token, subscription){
  return axios.get(DELETE_CLIENTS, {
    headers:{
      "Authorization" : `Bearer ${token}`,
      "Subscription" : subscription
    }
  })
}


