import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Select } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';



import { subscriptions, userInfo } from '../../requests/DirectWayProRrequest';

const Login = (props) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get('token');

  
    

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const dispatch = useDispatch();

  const [data, setData] = useState([
    {
      companyName: 'N/A',
      code: 'N/A',
      subscriptions: [
        {
          label: 'N/a',
          guid: 'N/A'
        }
      ]
    }
  ]);


  useEffect(() => {
    enableLoading();
    
    subscriptions(token)
      .then(res => {
        setData(res.data);
      }).catch(err=> {
        console.log(err);
      })

    disableLoading();

  }, []);

  const [selectedData, setSelectedData] = useState({
    CustomerCode: 'N/A',
    SubscriptionGuid: 'N/A'
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handleSubmit = async () => {
    let user = await userInfo(token);
    dispatch(auth.actions.userData({
      email: user.data.Email,
      fullname: user.data.FullName,
      username: user.data.UserName,
      pic: user.data.UrlImage
    }));
    dispatch(auth.actions.login(selectedData.SubscriptionGuid, token));
  }

  const handleChange = (event) => {
    setSelectedData({
      CustomerCode: event.target.value,
      SubscriptionGuid: selectedData.SubscriptionGuid
    });
  }

  const handleChangeSubscription = (event) => {
    setSelectedData({
      CustomerCode: selectedData.CustomerCode,
      SubscriptionGuid: event.target.value
    });
  }

  if(token === null){
    
    return <Redirect to="/Logout" />;
  }

  return (
    <>
      <div className="kt-login__head">
        &nbsp;&nbsp;
        <Link to="/logout"  className="kt-link kt-login__signup-link">
          Se déconnecter!
        </Link>
      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          
            <div className="form-group">
              <FormControl style={{ width: '-webkit-fill-available' }}>
                <InputLabel htmlFor="customer">Entreprise</InputLabel>
                <Select
                  value={selectedData.CustomerCode}
                  onChange={handleChange}
                  name="customer"
                >
                  {
                    data.map((x, index) => {
                      return (
                        <MenuItem value={x.code} key={index}>{x.companyName}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="form-group">
              <FormControl style={{ width: '-webkit-fill-available' }}>
                <InputLabel htmlFor="subscription">Abonnement</InputLabel>
                <Select
                  value={selectedData.SubscriptionGuid}
                  onChange={handleChangeSubscription}
                  name="subscription"
                >
                  {
                    selectedData.CustomerCode != 'N/A' && 
                    data.filter(x => x.code == selectedData.CustomerCode)[0].subscriptions.map((x, index) =>
                      <MenuItem value={x.guid} key={index}>{x.label}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </div>

            <div className="kt-login__actions">


              <button
                id="kt_login_signin_submit"
                disabled={false}
                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                  {
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                  }
                )}`}
                style={loadingButtonStyle}
                onClick={handleSubmit}
              >
                Se connecter
                  </button>
            </div>
          
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
